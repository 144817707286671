import { Inject, Injectable, Optional } from '@angular/core';
import { PW_ENVIRONMENT } from '@shared/core/constants/injectors.constant';
import { IEnvironment } from '@shared/core/interfaces/environment.interface';

@Injectable({ providedIn: 'root' })
export class UrlConstantsService {
  get baseUrl(): string {
    return this.environment.baseUrl;
  }

  public CLIENT_PREFERENCES = `${this.baseUrl}/api/v3/preferences`;
  public DRAFT_SHIPMENTS = `${this.baseUrl}/api/v3/shipment/draft`;
  public DRAFT_SHIPMENTS2 = `${this.baseUrl}/api/v3/draft-shipments/`;
  public PUBLIC_DRAFT_SHIPMENTS = `${this.baseUrl}/api/v3/public/draft-shipments/`;

  // public CALC_PRICE = `${this.baseUrl}/api/v3/calcprice/`;
  public PUBLIC_CALC_PRICE = `${this.baseUrl}/api/v3/public/calcprice/`;
  public PUBLIC_CALC_PRICE2 = `${this.baseUrl}/api/v3/public/calcprice2/`;

  public SHIPMENTS = `${this.baseUrl}/api/v3/shipments/`;
  public SHIPMENT = `${this.baseUrl}/api/v3/shipment/`;
  public SOCKET_CONNECTOR = `${this.environment.baseSocketUrl}/ws/connector/`;
  public LOGIN = `${this.baseUrl}/api/v3/login`;
  public REFRESH_TOKENS = `${this.baseUrl}/api/v3/token/refresh/`;
  public ACCEPT_TERMS = `${this.baseUrl}/api/v3/accept-terms/`;
  public SHIPMENT_SEARCH = `${this.SHIPMENT}search`;
  public SHIPMENT_RECURRING = `${this.SHIPMENT}recurring_shipment/manage_recurring_shipments`;
  public NOTIFICATION_EMAILS_FOR_RECURRING = `${this.SHIPMENT}recurring_shipment/notification-emails/`;
  public HEALTH_CHECK = `${this.baseUrl}/api/v3/health-check`;
  public FEED_BACK = `${this.baseUrl}/api/v3/feedback`;
  public FORGOT_PASSWORD = `${this.baseUrl}/api/v3/public/forgot-password`;
  public AVISIERUNG = `${this.baseUrl}/api/v3/public/avisierung`;
  public PUBLIC_INCIDENTS = `${this.baseUrl}/api/v3/incidents`;
  public USERS = `${this.baseUrl}/api/v3/users`;
  public DRIVERS = `${this.baseUrl}/api/v3/drivers`;
  public HUBS = `${this.baseUrl}/api/v3/hubs`;
  public ACCOUNT_DATA = `${this.baseUrl}/api/v3/account-settings/`;
  public INVOICE_CONTACT = `${this.baseUrl}/api/v3/account-settings/invoice-contact`;
  public CANCELED_SHIPMENTS = `${this.baseUrl}/api/v3/account-settings/canceled-shipments`;
  public ACCOUNT_NOTIFICATIONS_SETTINGS = `${this.baseUrl}/api/v3/account-settings/notification-emails`;
  public ACCOUNT_PAYMENTS = `${this.baseUrl}/api/v3/account-settings/payments`;
  public STRIPE_KEY = `${this.baseUrl}/api/v3/account-settings/payments/stripe-key`;
  public EMPTY_CONTAINERS = `${this.baseUrl}/api/v3/empty-containers/`;
  public EMPTY_CONTAINERS_AS_SENDER = `${this.EMPTY_CONTAINERS}as-sender`;
  public EMPTY_CONTAINERS_AS_CARRIER = `${this.EMPTY_CONTAINERS}as-carrier`;
  public DOCUMENTS = `${this.baseUrl}/api/v3/documents/`;
  public DOCUMENTS_DELIVERY_SLIP = `${this.DOCUMENTS}delivery-slip`;
  public DOCUMENTS_EMPTY_CONTAINERS_PICKUP = `${this.DOCUMENTS}empties-pickup`;
  public DOCUMENTS_INVOICE = `${this.DOCUMENTS}invoice`;
  public DOCUMENTS_REMINDER = `${this.DOCUMENTS}reminder`;
  public DOCUMENTS_PAYOUT = `${this.DOCUMENTS}payout`;
  public DOCUMENTS_LABEL = `${this.DOCUMENTS}label`;

  public CARRIER_DASHBOARD = `${this.baseUrl}/api/v3/carrier-dashboard/`;
  public OPEN_PRESALE_SHIPMENTS = `${this.CARRIER_DASHBOARD}open-pre-sales`;
  public CARRIER_NEW_SHIPMENTS = `${this.CARRIER_DASHBOARD}new-shipments`;
  public CARRIER_PRE_SALES = `${this.CARRIER_DASHBOARD}pre-sales`;
  public CARRIER_MAP_DATA = `${this.CARRIER_DASHBOARD}map-data`;
  public CARRIER_NOTIFICATIONS = `${this.CARRIER_DASHBOARD}notifications`;
  public SHIPMENTS_WITHOUT_DRIVER = `${this.CARRIER_DASHBOARD}shipments-without-driver`;
  public DISPO_COCKPIT_BADGES = `${this.CARRIER_DASHBOARD}badges`;
  public CARRIER_MONTHLY_EMPTY_SHEET = `${this.CARRIER_DASHBOARD}monthly_sheet`;

  public DOCUMENTS_PROOF_OF_DELIVERY = `${this.DOCUMENTS}proof-of-delivery`;
  public ADDRESS_BOOK = `${this.baseUrl}/api/v3/address-books/`;
  public OVERDUE_SHIPMENTS = `${this.SHIPMENT}overdue`;
  public PAYOUTS = `${this.baseUrl}/api/v3/payouts`;
  public ARCHIVE_SHIPMENTS = `${this.SHIPMENT}archive`;
  public CARRIER_SHIPMENTS = `${this.SHIPMENT}carrier/shipments-list`;
  public SHIPPER_OPEN_SHIPMENTS = `${this.SHIPMENT}open`;
  public SHIPPER_IN_PROGRESS_SHIPMENTS = `${this.SHIPMENT}inprogress`;
  public SHIPPER_DONE_SHIPMENTS = `${this.SHIPMENT}done`;
  public DISABLE_DATES = `${this.baseUrl}/api/v3/public/disabled-dates`;
  public ZIP_LOCATIONS = `${this.baseUrl}/api/v3/public/zip-locations`;
  public API_TOKENS = `${this.baseUrl}/api/v3/api-management/api_token`;
  public API_DEFAULT_PAYMENT_PROVIDERS = `${this.baseUrl}/api/v3/api-management/default_payment`;
  public API_PAYMENT_PROVIDERS = `${this.baseUrl}/api/v3/api-management/charge_providers`;
  public CREATE_TOKEN = `${this.baseUrl}/api/v3/api-management/create_token`;
  public DEFAULT_PAYMENT = `${this.baseUrl}/api/v3/api-management/default_payment`;
  public DISPATCH_DRIVER = `${this.SHIPMENT}dispatch`;
  public SHIPPER_PACKET_TYPES = `${this.baseUrl}/api/v3/clients/packet-types`;
  public INVOICES = `${this.baseUrl}/api/v3/account-billings/`;
  public CARRIER_DOWNLOAD_OVERDUE_SHIPMENTS_EXCEL = `${this.baseUrl}/api/v3/carrier/export-overdue-shipments/excel/`;
  public CARRIER_DOWNLOAD_OPEN_SHIPMENTS_PICKUP = `${this.baseUrl}/api/v3/carrier/export-open-shipments/pickup/`;
  public CARRIER_DOWNLOAD_OPEN_SHIPMENTS_DELIVERY = `${this.baseUrl}/api/v3/carrier/export-open-shipments/delivery/`;

  public DRIVER_PROFILE = `${this.baseUrl}/api/v3/driver/profile`;
  public DRIVER_IMAGE = `${this.baseUrl}/api/v3/driver/image`;
  public DRIVER_SYNC = `${this.baseUrl}/api/v3/driver/sync`;
  public DRIVER_ACCEPT = `${this.baseUrl}/api/v3/driver/accept`;

  public SHIPMENTS_AT_HUB = `${this.SHIPMENT}set-shipments-at-hub`;

  constructor(@Optional() @Inject(PW_ENVIRONMENT) public environment: IEnvironment) { }

  public STRIPE_CLIENT_SECRET = (last4) => `${this.baseUrl}/api/v3/account-settings/payments/client-secret/${last4}`;

  public CARRIER_DASHBOARD_SHIPMENT = (shipmentNumber: string) =>
    `${this.CARRIER_DASHBOARD}shipments/${shipmentNumber}`;
  public BUNDLE_BY_ID = (bundleId: number) => `${this.SHIPMENT}pickup-bundle/${bundleId}`;
  public ACCEPT_BUNDLE_BY_ID = (bundleId: number) => `${this.BUNDLE_BY_ID(bundleId)}/accept`;
  public CARRIER_ACCEPT_PRESALE_BY_NUMBER = (presaleNumber: string) =>
    `${this.CARRIER_DASHBOARD}pre-sales/${presaleNumber}`;
  public DRAFT_SHIPMENT_BY_NUMBER = (draftNumber: string) => `${this.DRAFT_SHIPMENTS2}${draftNumber}`;
  public PUBLIC_DRAFT_SHIPMENT_BY_NUMBER = (draftNumber: string) => `${this.PUBLIC_DRAFT_SHIPMENTS}${draftNumber}`;
  public CHARGE_DRAFT_SHIPMENT = (draftNumber: string, paymentOptionId: number) =>
    `${this.DRAFT_SHIPMENTS2}${draftNumber}/payment-charge/${paymentOptionId}`;
  public VERIFY_DISCOUNT_CODE = (draftNumber: string) =>
    `${this.DRAFT_SHIPMENTS2}${draftNumber}/verify-promo-code`;
  public SHIPMENT_BY_NUMBER = (shipmentNumber: string) => `${this.SHIPMENTS}${shipmentNumber}`;
  public CARRIER_SHIPMENT_BY_NUMBER = (shipmentNumber: string) => `${this.SHIPMENTS}carrier/${shipmentNumber}`;
  public SHIPPER_SHIPMENT_BY_NUMBER = (shipmentNumber: string) => `${this.SHIPMENTS}shipper/${shipmentNumber}`;
  public SHIPMENT_AT_HUB = (shipmentNumber: string) => `${this.SHIPMENT}${shipmentNumber}/at-hub`;
  public EDIT_SHIPMENT_DELIVERY_ADDRESS = (shipmentNumber: string) =>
    `${this.SHIPMENT}${shipmentNumber}/edit-delivery-address`;
  public EDIT_SHIPMENT_PICKUP_ADDRESS = (shipmentNumber: string) =>
    `${this.SHIPMENT}${shipmentNumber}/edit-pickup-address`;
  public SHIPPER_EDIT_SHIPMENT_DATES = (shipmentNumber: string) => `${this.SHIPMENT}shipper/${shipmentNumber}/edit-dates`;
  public CARRIER_EDIT_SHIPMENT_DATES = (shipmentNumber: string) => `${this.SHIPMENT}carrier/${shipmentNumber}/edit-dates`;
  public ACCEPT_SHIPMENT_BY_NUMBER = (shipmentNumber: string) => `${this.SHIPMENT}${shipmentNumber}/accept`;
  public DISPATCH_DRIVER_TO_SHIPMENT = (shipmentNumber: string) => `${this.SHIPMENT}${shipmentNumber}/take/2`;
  public DISPATCH_DRIVER_TO_BUNDLE = (bundleId: number) => `${this.BUNDLE_BY_ID(bundleId)}/take/2`;
  public SHIPMENT_CHANGES_HISTORY = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}/history-events`;
  public CARRIER_SHIPMENT_CHANGES_HISTORY = (shipmentNumber: string) =>
    `${this.CARRIER_SHIPMENT_BY_NUMBER(shipmentNumber)}/history-events`;

  public CARRIER_SHIPMENT_UPDATE_ALERTS_PICKUP = (shipmentNumber: string) =>
    `${this.SHIPMENT}carrier/${shipmentNumber}/alert/pick`;

  public CARRIER_SHIPMENT_UPDATE_ALERTS_DELIVERY = (shipmentNumber: string) =>
    `${this.SHIPMENT}carrier/${shipmentNumber}/alert/delivery`;

  public CARRIER_SHIPMENT_CHANGE_DELIVERY_STATUS = (shipmentNumber: string) =>
    `${this.SHIPMENT}carrier/${shipmentNumber}/change_delivery_status`;

  // public SHIPMENT_HISTORY_EVENTS = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}/events`;
  public SHIPPER_SHIPMENT_HISTORY_EVENTS = (shipmentNumber: string) => `${this.SHIPPER_SHIPMENT_BY_NUMBER(shipmentNumber)}/events`;
  public CARRIER_SHIPMENT_HISTORY_EVENTS = (shipmentNumber: string) =>
    `${this.CARRIER_SHIPMENT_BY_NUMBER(shipmentNumber)}/events`;
  public SHIPPER_SHIPMENT_EFFORTS = (shipmentNumber: string) =>
    `${this.SHIPPER_SHIPMENT_BY_NUMBER(shipmentNumber)}/shipment-additional-items`;
  public CARRIER_SHIPMENT_EFFORTS = (shipmentNumber: string) =>
    `${this.CARRIER_SHIPMENT_BY_NUMBER(shipmentNumber)}/shipment-additional-items`;
  public SHIPMENT_DELIVERY_IMAGES = (shipmentNumber: string) =>
    `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}/delivery-images`;

  public CARRIER_UPLOAD_SHIPMENT_IMAGE = (shipmentNumber: string) =>
    `${this.CARRIER_SHIPMENT_BY_NUMBER(shipmentNumber)}/upload_shipment_image`;

  public CARRIER_UPLOAD_SHIPMENT_SIGNATURE_IMAGE = (shipmentNumber: string) =>
    `${this.CARRIER_SHIPMENT_BY_NUMBER(shipmentNumber)}/upload_shipment_signature_image`;

  public CARRIER_UPLOAD_SHIPMENT_DOCUMENT = (shipmentNumber: string) =>
    `${this.CARRIER_SHIPMENT_BY_NUMBER(shipmentNumber)}/upload_shipment_document`;

  public CLIENT_ACCOUNT_MESSAGES = (type) => `${this.baseUrl}/api/v3/account-messages/${type}`;

  public SHIPMENT_PICK_IMAGES = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}/pick-images`;
  public SHIPMENT_FILES = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}/files`;
  public SHIPPER_SHIPMENT_FILES = (shipmentNumber: string) => `${this.SHIPPER_SHIPMENT_BY_NUMBER(shipmentNumber)}/files`;
  public SHIPMENT_PACKETS = (shipmentNumber: string) => `${this.SHIPMENT_BY_NUMBER(shipmentNumber)}/packets`;
  public SHIPPER_SHIPMENT_PACKETS = (shipmentNumber: string) => `${this.SHIPPER_SHIPMENT_BY_NUMBER(shipmentNumber)}/packets`;
  public SHIPPER_EDIT_SHIPMENT_OPTIONS = (shipmentNumber: string) => `${this.SHIPPER_SHIPMENT_BY_NUMBER(shipmentNumber)}/edit-options`;
  public SHIPMENT_PACKET_TYPES = (shipmentNumber: string) => `${this.SHIPMENT}${shipmentNumber}/sender-packet-types`;

  public SHIPMENT_CARGO_ITEMS = (shipmentNumber: string) => `${this.SHIPMENT}${shipmentNumber}/cargo-items`;
  public SHIPMENT_CARGO_ITEM = (shipmentNumber: string, itemId: number) =>
    `${this.SHIPMENT_CARGO_ITEMS(shipmentNumber)}/${itemId}`;

  public SHIPMENT_CARGO_ITEM_CALL_PRICE_EDIT = (shipmentNumber, cargoItemId) => `${this.SHIPMENT}${shipmentNumber}/cargo-items/${cargoItemId}/edit/callprice`;
  public SHIPMENT_CARGO_ITEM_CALL_PRICE_ADD = (shipmentNumber) => `${this.SHIPMENT}${shipmentNumber}/cargo-items/add/callprice`;
  
  public ACCOUNT_PAYMENT_BY_ID = (id) => `${this.ACCOUNT_PAYMENTS}/${id}`;
  public ACCOUNT_NOTIFICATIONS_SETTING_BY_ID = (id) => `${this.ACCOUNT_NOTIFICATIONS_SETTINGS}/${id}`;
  public ADDRESS_BOOK_BY_ID = (id: number) => `${this.ADDRESS_BOOK}${id}`;
  public CONTACTS = (addressId: number) => `${this.ADDRESS_BOOK_BY_ID(addressId)}/contacts/`;
  public CONTACT_BY_ID = (addressId: number, contactId: number) => `${this.CONTACTS(addressId)}${contactId}`;
  public USER_BY_ID = (userId: number) => `${this.USERS}/${userId}`;
  public LOCK_USER_DEVICE = (userId: number) => `${this.USERS}/${userId}/lock-devices`;
  public DRIVER_SHIPMENTS = (userId: number) => `${this.USERS}/${userId}/shipments`;
  public DRIVER_IMAGES = (userId: number) => `${this.DRIVERS}/${userId}/selfie`;
  public HUBS_BY_ID = (hubId: number) => `${this.HUBS}/${hubId}`;

  public EMPTY_CONTAINERS_ORDER_AS_SENDER = (sideId: number, carrierId: number) =>
    `${this.EMPTY_CONTAINERS_AS_SENDER}/order/${sideId}/${carrierId}`;
  public MAKE_RETURN_ORDER_AS_CARRIER = (sideId: number) =>
    `${this.EMPTY_CONTAINERS_AS_CARRIER}/order/delivery/${sideId}`;
  public MAKE_PICKUP_ORDER_AS_CARRIER = (sideId: number) =>
    `${this.EMPTY_CONTAINERS_AS_CARRIER}/order/pickup/${sideId}`;
  public EMPTY_CONTAINERS_EXPORT_AS_SENDER = (sideId: number, carrierId: number) =>
    `${this.EMPTY_CONTAINERS_AS_SENDER}/export/${sideId}/${carrierId}`;
  public EMPTY_CONTAINERS_EXPORT_AS_CARRIER = (sideId: number) =>
    `${this.EMPTY_CONTAINERS_AS_CARRIER}/export/${sideId}`;

  public PUBLIC_INCIDENT_SHIPMENT = (id: number) => `${this.PUBLIC_INCIDENTS}/shipments/${id}`;
  public PUBLIC_INCIDENT_BY_ID = (id: number) => `${this.PUBLIC_INCIDENTS}/${id}`;
  public RESOLVE_PUBLIC_INCIDENT = (id: number) => `${this.PUBLIC_INCIDENT_BY_ID(id)}/resolve`;

  public DELIVERY_CONTROL = (hash: string) => `${this.baseUrl}/api/v3/delivery-control/${hash}`;
  public SIMPLE_DELIVERY_CONTROL = (hash: string) => `${this.baseUrl}/api/v3/simple-delivery-control/${hash}`;
  public DELIVERY_CONTROL_CONFIRM = (hash: string) => `${this.baseUrl}/api/v3/delivery-control/confirm/${hash}`;
  public TRACKER_BY_HASH = (hash: string) => `${this.baseUrl}/api/v3/public/tracking_by_hash/${hash}`;
  public INVOICE_BY_HASH = (hash: string) => `${this.baseUrl}/api/v3/public/download-invoice/${hash}`;
  public TRACKER_BY_NUMBER = (shipmentNumber: string, zip: string) =>
    `${this.baseUrl}/api/v3/public/tracking_by_number/${shipmentNumber}/${zip}`;
  public RATING_BY_HASH = (hash: string) => `${this.baseUrl}/api/v3/rating/${hash}/`;

  public INVOICES_REMINDER_BY_ID = (reminderId: number) =>
    `${this.baseUrl}/api/v3/account-billings/reminders/${reminderId}`;
  public INVOICES_DOWNLOAD_PDF_BY_ID = (invoiceId: string) =>
    `${this.baseUrl}/api/v3/account-billings/invoices/${invoiceId}/pdf`;
  public INVOICES_DOWNLOAD_EXCEL_BY_ID = (invoiceId: string) =>
    `${this.baseUrl}/api/v3/account-billings/invoices/${invoiceId}/excel`;
  public OPEN_CLIENT_SHIPMENTS_DOCS = (clientId: number, type: 'pdf' | 'excel') =>
    `${this.baseUrl}/api/v3/private/exports/clients/${clientId}/export-open-shipments/${type}/`;
  public CLIENT_PALLET_PRICES_DOC = (clientId: number) =>
    `${this.baseUrl}/api/v3/private/exports/clients/${clientId}/export-pal-prices/`;
  public CLIENT_DRIVERS_DOC = (clientId: number) =>
    `${this.baseUrl}/api/v3/private/exports/clients/${clientId}/export-drivers/`;
  public CLIENT_RATINGS_DOC = (clientId: number) =>
    `${this.baseUrl}/api/v3/private/exports/clients/${clientId}/export-ratings/`;
  public CLIENT_SHIPMENTS_DOC = (clientId: number) =>
    `${this.baseUrl}/api/v3/private/exports/clients/${clientId}/export-shipments/`;
  public CLIENT_BULK_EDIT_PRICE = (clientId: number) =>
    `${this.baseUrl}/api/v3/private/exports/clients/${clientId}/bulk-edit-prices/`;

  public SHIPMENT_RECURRING_EDIT = (recurringShipmentPlanId) =>
    `${this.SHIPMENT}recurring_shipment/manage_recurring_shipments/${recurringShipmentPlanId}`;

  public RELEASE_RECURRING_SHIPMENT = (draftNumber: string) =>
    `${this.SHIPMENT}recurring_shipment/release-recurring/${draftNumber}/`;

  public DISABLE_TOKEN = (tokenString: string) =>
    `${this.baseUrl}/api/v3/api-management/api_token/disable/${tokenString}`;

  public DRIVERS_CHARGE_LIST = (driverId) => `${this.baseUrl}/api/v3/drivers/${driverId}/charge-list`;
}
