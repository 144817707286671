import { IEnvironment } from '@shared/core/interfaces/environment.interface';

export const environment: IEnvironment = {
  production: true,
  envName: 'prod',
  baseUrl: 'https://api.pickwings.ch',
  baseSocketUrl: 'wss://api.pickwings.ch',
  firebase: {
    apiKey: 'AIzaSyDE3GoG4Tczcj2_yhsZgUhhDOmvIeEZJWw',
    authDomain: 'pickwings-webapps.firebaseapp.com',
    projectId: 'pickwings-webapps',
    storageBucket: 'pickwings-webapps.appspot.com',
    messagingSenderId: '108292520554',
    appId: '1:108292520554:web:19def66446e6bdf5be2813',
    measurementId: 'G-BHDPPLZDCL',
  },
  showUpdateAvailable: true,
  isBuildInfoShown: false,
  defaultLanguage: 'en',
  panelType: 'driver',
  languages: ['en', 'de'],
  relateWebsite: {
    shipper: 'https://shipper.pickwings.ch/',
    carrier: 'https://carrier.pickwings.ch/',
    about: 'https://pickwings.ch/',
  },
};
