import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@pw-utils/guards/auth/auth.guard';
import { SupportGuard } from '@pw-utils/guards/support/support.guard';
import { TermsGuard } from '@pw-utils/guards/terms/terms.guard';

const routes: Routes = [
  {
    path: 'main',
    canActivate: [AuthGuard, SupportGuard],
    data: { needAdminAccess: false, loginPath: '/login', termsPath: '/accept-terms', unSupportPath: '/not-supported' },
    loadChildren: () => import('./layouts/private-layout/private-layout.module').then((m) => m.PrivateLayoutModule),
  },
  {
    path: '',
    loadChildren: () => import('./layouts/public-layout/public-layout.module').then((m) => m.PublicLayoutModule),
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
