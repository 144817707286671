import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { versions } from '@assets/versions';

if (environment.production) {
  Sentry.init({
    dsn: 'https://e7588d1674ce4f819a05ad5623dc6daf@o516287.ingest.sentry.io/6106863',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://driver.dev.pickwings.ch',
          'https://driver.staging.pickwings.ch',
          'https://driver.pickwings.ch',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    release: versions.hash,
    dist: new Date(versions.date)
      .toISOString()
      .replace(/-/g, '')
      .replace(/T/g, '')
      .replace(/Z/g, '')
      .replace(/:/g, '')
      .replace('.', ''),
    tracesSampleRate: 0.1,
    environment: environment.envName,
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => {
    console.log(
      `🚚 🚚 🚚 Welcome to Pickwings!\nDoes this page need fixes or improvements? Send up some feedback at info@pickwings.ch!`
    );
    console.log(`Bootstrap success: ${versions.hash}, ${versions.date}`);
  })
  .catch((err) => console.error(err));
